import React from 'react';
import { Row, Col } from 'antd';
import DOMPurify from 'isomorphic-dompurify';
import styled from '@emotion/styled';

import CardComponent from './card';
import { InternalContainer } from './section-styles';

import theme from '../../theme';

type SectionProps = {
  title: string;
  description: string;
  handleClick: (link: string) => void;
  cards: Record<string, any>;
};

type CardItem = {
  sort: number;
  translation: {
    home_section_tagline: string;
    home_section_description: string;
    home_section_cta_text: string;
  };
  home_section_cta_link: string;
  home_section_image: {
    data: {
      full_url: string;
    };
  };
};

const AdditionalSection = styled.section`
  overflow: hidden;
  position: relative;
  margin-bottom: 100px;
  @media (max-width: ${theme.screenXsMax}) {
  }
`;

const AdditionalTitle = styled.h1`
  color: ${theme.colorsBlack};
  font-weight: bold;
  font-size: 40px;
  letter-spacing: -1px;
  line-height: 60px;
  margin-bottom: 20px;
  text-align: center;
`;

const AdditionalDescription = styled.div`
  color: ${theme.colorsBlack};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 60px;
  text-align: center;
`;

const CardRow = styled(Row)`
  text-align: center;
`;

const CardCol = styled(Col)`
  margin-bottom: 30px;
`;

function AdditionalComponent({ title, description, cards, handleClick }: SectionProps) {
  return (
    <InternalContainer>
      <Row>
        <Col xs={24}>
          <AdditionalSection>
            <Row justify="center">
              <Col md={24} lg={12}>
                <AdditionalTitle>{title}</AdditionalTitle>
                <AdditionalDescription dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
              </Col>
            </Row>
            {cards.length > 0 && (
              <CardRow justify="center">
                {cards.map((card: CardItem) => {
                  return (
                    <CardCol lg={8} sm={13} xs={24} key={card.sort}>
                      <CardComponent
                        cta={card.translation?.home_section_cta_text}
                        ctaLink={card.home_section_cta_link}
                        description={card.translation?.home_section_description}
                        imageUrl={card.home_section_image.data.full_url}
                        handleClick={handleClick}
                        tagLine={card.translation?.home_section_tagline}
                      />
                    </CardCol>
                  );
                })}
              </CardRow>
            )}
          </AdditionalSection>
        </Col>
      </Row>
    </InternalContainer>
  );
}

export default React.memo(AdditionalComponent);
