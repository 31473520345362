import React, { useCallback } from 'react';
import { Row, Col } from 'antd';
import DOMPurify from 'isomorphic-dompurify';
import styled from '@emotion/styled';

import { InternalContainer, SectionBackground, StyledImage } from './section-styles';

import Button from '../button';
import theme from '../../theme';

type SectionProps = {
  tagLine: string;
  description: string;
  imageUrl?: string;
  cta: string;
  ctaLink: string;
  handleClick: (link: string) => void;
};

const TestimonialContainer = styled.div`
  position: relative;
  margin-bottom: 160px;
  @media (max-width: ${theme.screenMdMax}) {
    margin-bottom: 72px;
  }
`;

const TestimonialBackground = styled(SectionBackground)`
  max-width: 100%;
  max-height: 544px;
  top: 60px;

  @media (max-width: ${theme.screenMdMax}) {
    top: 0;
    max-height: 100%;
  }
`;

const TestimonialSection = styled.section`
  position: relative;
  @media (max-width: ${theme.screenMdMax}) {
    padding: 24px 0;
  }
`;

const TestimonialWrapper = styled.div`
  margin-top: 96px;
  padding-left: 128px;
  @media (max-width: ${theme.screenMdMax}) {
    margin-top: 0;
    padding-left: 0;
  }
`;

const TestimonialTagline = styled.div`
  color: ${theme.colorsBlack};
  font-weight: bold;
  display: inline-block;
  font-size: 130px;
  margin-bottom: 16px;
  padding: 0;
  height: 75px;
  overflow: hidden;
`;

const TestimonialDescription = styled.div`
  p {
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 23px;
    text-align: left;
    margin-bottom: 40px;
    color: ${theme.colorsBlack};
  }

  p:first-of-type {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
  }
`;

export default function TestimonialComponent({ description, cta, ctaLink, handleClick, imageUrl }: SectionProps) {
  const handleOnClick = useCallback(() => {
    handleClick(ctaLink);
  }, [ctaLink, handleClick]);

  return (
    <TestimonialContainer>
      <TestimonialBackground />
      <InternalContainer>
        <TestimonialSection>
          <Row justify="center">
            {imageUrl && (
              <Col lg={10} md={12} xs={24}>
                <StyledImage src={imageUrl} alt="testimonial image" />
              </Col>
            )}

            <Col lg={14} xs={24}>
              <TestimonialWrapper>
                <TestimonialTagline>“</TestimonialTagline>
                <div>
                  <TestimonialDescription dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
                  <Button type="primary-blue" onClick={handleOnClick}>
                    {cta}
                  </Button>
                </div>
              </TestimonialWrapper>
            </Col>
          </Row>
        </TestimonialSection>
      </InternalContainer>
    </TestimonialContainer>
  );
}
