import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import DOMPurify from 'isomorphic-dompurify';
import { Row, Col } from 'antd';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import theme from '../../theme';

type SectionProps = {
  tagLine: string;
  description: string;
  imageUrl?: string;
  cta: string;
  ctaLink: string;
  handleClick: (link: string) => void;
};

const CardContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  background-color: ${theme.colorsWhite};
  box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.1);
  margin-right: 32px;

  @media (max-width: ${theme.screenLgMax}) {
    margin-right: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  }
`;

const CardTagline = styled.h6`
  color: ${theme.colorsBlack};
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.39px;
  line-height: 29px;
  text-align: left;
  height: 60px;
`;

const ImageWrapper = styled.div`
  margin: 25px 25px 20px 25px;
`;

const CardImg = styled.img`
  width: 100%;
`;

const TextWrapper = styled.div`
  margin: 0 24px 24px 24px;
`;

const CardDescription = styled.div`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: left;
  height: 70px;
  margin-bottom: 36px;
`;

const CardCta = styled.div`
  text-align: left;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
  color: ${theme.colorsSecondaryBlue};
  position: relative;
  cursor: pointer;

  & span {
    text-decoration: underline;
  }
`;

const ChevronRightIcon = styled(ChevronRightRoundedIcon)`
  vertical-align: middle;
  position: absolute;
  right: 0;
`;

function Card({ description, imageUrl, tagLine, ctaLink, cta, handleClick }: SectionProps) {
  const handleOnClick = useCallback(() => {
    handleClick(ctaLink);
  }, [ctaLink, handleClick]);

  return (
    <CardContainer>
      <Row>
        <ImageWrapper>
          {imageUrl && (
            <Col>
              <CardImg src={imageUrl} alt="card" />
            </Col>
          )}
        </ImageWrapper>
        <TextWrapper>
          <CardTagline>{tagLine}</CardTagline>
          <CardDescription dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
          <CardCta onClick={handleOnClick}>
            <span>{cta}</span>
            <ChevronRightIcon titleAccess={`go to ${CardTagline}`} />
          </CardCta>
        </TextWrapper>
      </Row>
    </CardContainer>
  );
}

export default React.memo(Card);
