import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { Row, Col } from 'antd';

import {
  InternalContainer,
  SectionBackground,
  StyledButton,
  StyledDisplay,
  StyledH5,
  StyledHtmlContainer,
  StyledImage,
} from './section-styles';

import theme from '../../theme';

type SectionProps = {
  tagLine: string;
  description: string;
  imageUrl?: string;
  title: string;
  sort: number;
  cta: string;
  ctaLink: string;
  handleClick: (link: string) => void;
};

const Section = styled.section`
  overflow: hidden;
  position: relative;
  padding: 64px 0;
  &.even {
    margin: 100px 0;
  }
  @media (max-width: ${theme.screenXsMax}) {
    padding: 72px 0;
    &.even {
      margin: 0px;
    }
  }
`;

const SectionTaglineContainer = styled.div`
  padding-bottom: 24px;
`;

const SectionTagLineBorder = styled.hr`
  background-color: ${theme.colorsPrimaryBlue};
  width: 24%;
  height: 5px;
  border-style: none;
`;

const SectionTagline = styled(StyledH5)`
  color: ${theme.colorsPrimaryBlue};
  padding-top: 16px;
`;

const SectionTitle = styled(StyledDisplay)`
  letter-spacing: -1px;
  line-height: 60px;
  padding-bottom: 24px;
`;

const SectionDescription = styled(StyledHtmlContainer)`
  margin-bottom: 40px;
`;

const SectionImage = styled(StyledImage)`
  @media (max-width: ${theme.screenMdMax}) {
    margin-top: 32px;
  }
`;

export default function SectionComponent({
  cta,
  ctaLink,
  handleClick,
  imageUrl,
  sort,
  description,
  tagLine,
  title,
}: SectionProps) {
  const handleOnClick = useCallback(() => {
    handleClick(ctaLink);
  }, [ctaLink, handleClick]);

  const renderLeftSection = () => {
    return (
      <Section>
        <Row justify="space-between" align="middle">
          <Col xs={24} lg={10}>
            <SectionTaglineContainer>
              <SectionTagLineBorder />
              <SectionTagline>{tagLine}</SectionTagline>
            </SectionTaglineContainer>

            <SectionTitle>{title}</SectionTitle>
            <SectionDescription dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
            <StyledButton type="primary-blue" onClick={handleOnClick}>
              {cta}
            </StyledButton>
          </Col>
          {imageUrl && (
            <Col xs={24} lg={{ span: 12 }}>
              <SectionImage src={imageUrl} alt="testimonial image" />
            </Col>
          )}
        </Row>
      </Section>
    );
  };
  const renderRightSection = () => {
    return (
      <Section className="even">
        <Row justify="space-between" align="middle">
          {imageUrl && (
            <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
              <SectionImage src={imageUrl} alt="testimonial image" />
            </Col>
          )}

          <Col xs={{ span: 24, order: 1 }} lg={{ span: 10, order: 2 }}>
            <SectionTaglineContainer>
              <SectionTagLineBorder />
              <SectionTagline>{tagLine}</SectionTagline>
            </SectionTaglineContainer>

            <SectionTitle>{title}</SectionTitle>
            <SectionDescription dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
            <StyledButton type="primary-blue" onClick={handleOnClick}>
              {cta}
            </StyledButton>
          </Col>
        </Row>
      </Section>
    );
  };

  return (
    <div>
      <SectionBackground hidden={sort % 2 === 0} />
      <InternalContainer>{sort % 2 === 0 ? renderRightSection() : renderLeftSection()}</InternalContainer>
    </div>
  );
}
