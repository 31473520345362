import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Carousel } from 'antd5';
import styled from '@emotion/styled';
import { CustomArrowProps } from 'react-slick';

import { StyledButton, StyledHtmlContainer, StyledImage } from './section-styles';

import theme from '../../theme';
import { FeaturedEventsData } from '../../types/cms/models/featured-events';
import { navigateTo } from '../../state/actions/routing';
import { formatDate, getTimeZoneByName } from '../../utils/date';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px 25px 25px;

  @media (max-width: ${theme.screenMd}) {
    padding: 0;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${theme.colorsBlack};
`;

const Description = styled(StyledHtmlContainer)`
  font-size: 18px;
  color: ${theme.colorsBlack};
  margin-top: 16px;
`;

const Date = styled.div`
  font-size: 18px;
  color: ${theme.colorsBlack};
  font-weight: bold;
  margin-top: 13px;
`;

const StyledDivButton = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  margin-right: 15px;
`;

const DescriptionWrapper = styled.div`
  width: 50%;
  padding-right: 15px;
`;

const ImageWrapper = styled.div`
  margin: 0;
  width: 50%;

  @media (max-width: ${theme.screenMd}) {
    margin: 20px 0;
  }
`;

type FeaturedEventsCardProps = {
  title: string;
  description: string;
  date: string;
  timezone: string;
  registerLabel: string;
  learnLabel: string;
  imageUrl?: string;
  registerButtonLink: string;
  learnMoreButtonLink: string;
  handleClickRegister: (link: string) => void;
  handleClickLearnMore: (link: string) => void;
};

const FeaturedEventsCard: React.FC<FeaturedEventsCardProps> = ({
  title,
  description,
  registerLabel,
  learnLabel,
  date,
  timezone,
  imageUrl,
  registerButtonLink,
  learnMoreButtonLink,
  handleClickRegister,
  handleClickLearnMore,
}) => {
  const handleOnClickRegister = useCallback(() => {
    handleClickRegister(registerButtonLink);
  }, [registerButtonLink, handleClickRegister]);

  const handleOnClickLearnMore = useCallback(() => {
    handleClickLearnMore(learnMoreButtonLink);
  }, [learnMoreButtonLink, handleClickLearnMore]);

  const timezoneData = getTimeZoneByName(timezone);

  return (
    <Wrapper>
      <DescriptionWrapper>
        <Title>{title}</Title>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <Date>{`${formatDate(date, 'dddd MMMM D, YYYY ∙ h:ssa')}  ${timezoneData?.abbr || ''}`}</Date>
        <StyledDivButton>
          <ButtonWrapper>
            <StyledButton type="primary-blue" onClick={handleOnClickRegister}>
              {registerLabel}
            </StyledButton>
          </ButtonWrapper>
          <StyledButton type="primary" onClick={handleOnClickLearnMore}>
            {learnLabel}
          </StyledButton>
        </StyledDivButton>
      </DescriptionWrapper>
      <ImageWrapper>
        <StyledImage src={imageUrl} alt={title} />
      </ImageWrapper>
    </Wrapper>
  );
};

const EventSection = styled.section`
  padding: 60px 30px;
  margin: 70px auto;
  max-width: 1120px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: ${theme.screenMd}) {
    padding: 50px 20px;
  }
`;

type SlickArrowProps = {
  slideCount?: CustomArrowProps;
  currentSlide?: CustomArrowProps;
  isNextArrow?: boolean;
};

const SlickArrow: React.FC<SlickArrowProps> = ({ slideCount, currentSlide, isNextArrow, ...props }) => (
  <button
    {...props}
    {...slideCount}
    {...currentSlide}
    className={`slick-arrow ${isNextArrow ? 'slick-next' : 'slick-prev'}`}
    aria-hidden="true"
    type="button"
  ></button>
);

const StyledCarousel = styled(Carousel)`
  /* dots */
  .slick-dots-bottom {
    bottom: -120px;
  }

  .slick-dots li {
    background: #cdcdcd;
    position: relative;
    width: 8px !important;
    height: 8px;
    border-radius: 50%;

    button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #cdcdcd;
    }
  }

  .slick-dots li.slick-active button {
    background: ${theme.colorsPrimaryBlue};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    &:not(:last-of-type) {
      margin-right: 15px;
    }
  }

  /* arrows */
  .slick-prev:hover,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-next:focus {
    color: ${theme.colorsPrimaryBlue};
    opacity: 1;
    font-size: 25px;

    @media (max-width: ${theme.screenMd}) {
      font-size: 35px;
    }
  }

  .slick-next {
    right: 35% !important;

    @media (max-width: ${theme.screenMd}) {
      right: 15% !important;
    }
  }

  .slick-prev {
    left: 35% !important;

    @media (max-width: ${theme.screenMd}) {
      left: 15% !important;
    }
  }

  .slick-prev,
  .slick-next {
    top: unset;
    bottom: -135px;
    color: ${theme.colorsPrimaryBlue};
    opacity: 0.5;
    font-size: 25px;
    margin-top: 30px;
    width: 30px;
    height: 30px;

    @media (max-width: ${theme.screenMd}) {
      margin-top: 10px;
      font-size: 35px;
    }
  }
`;

export default function FeaturedEventsComponent({ data }: FeaturedEventsData) {
  const dispatch = useDispatch();
  const myRef = useRef(null);

  const onCtaClick = (link: string) => {
    if (link) {
      if (link.indexOf('http') === 0) {
        window.open(link, '_blank');
        return;
      }
      dispatch(navigateTo(link));
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore: Object is possibly 'null'.
      const top = myRef && myRef.current ? myRef.current.offsetTop : 1000;
      window.scrollTo(0, top);
    }
  };

  return (
    <EventSection>
      <StyledCarousel infinite arrows prevArrow={<SlickArrow />} nextArrow={<SlickArrow isNextArrow />}>
        {data?.map(item => {
          return (
            <FeaturedEventsCard
              key={item.directusId}
              handleClickRegister={onCtaClick}
              handleClickLearnMore={onCtaClick}
              registerLabel={item.translation?.featured_events_register_button_label || ''}
              registerButtonLink={item.featured_events_register_button_link}
              learnLabel={item.translation?.featured_events_learn_more_button_label || ''}
              learnMoreButtonLink={item.featured_events_learn_more_button_link}
              title={item.featured_events_title || ''}
              description={item.featured_events_description || ''}
              imageUrl={item.featured_events_image.data.full_url}
              date={item.date}
              timezone={item?.featured_event_timezone?.timezone_value}
            />
          );
        })}
      </StyledCarousel>
    </EventSection>
  );
}
