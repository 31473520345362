import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import DOMPurify from 'isomorphic-dompurify';

import { StyledButton, StyledHtmlContainer } from './section-styles';

import theme from '../../theme';

const StyledBottomPanel = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  z-index: 1;
  bottom: 14.5px;
  width: 1440px;
  height: 151px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: ${theme.screenMd}) {
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
    padding: 20px 0;
  }

  @media (max-width: ${theme.screenXlMax}) {
    max-width: inherit;
    width: calc(100% - 60px);
    padding: 10px 30px;
    margin: 0 30px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${theme.screenMd}) {
    flex-direction: column;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  margin-left: 15px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${theme.colorsBlack};
`;

const Description = styled(StyledHtmlContainer)`
  font-size: 18px;
  color: ${theme.colorsBlack};

  @media (max-width: ${theme.screenMd}) {
    margin: 15px 0;
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 10%;
  right: 25px;
  color: ${theme.colorsBlack};
  cursor: pointer;

  @media (max-width: ${theme.screenMd}) {
    top: 5%;
    right: 15px;
  }
`;

const StyledImage = styled.img`
  width: 144px;
  height: 96px;
  border-radius: 5px;
`;

type BottomPanelProps = {
  image?: string;
  title?: string;
  description: string;
  link: string;
  handleClick: (link: string) => void;
  ctaLabel: string;
};

const BottomPanel: React.FC<BottomPanelProps> = ({ image, title, description, link, handleClick, ctaLabel }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const handleOnClick = useCallback(() => {
    handleClick(link);
  }, [link, handleClick]);

  return (
    <>
      {isOpen ? (
        <StyledBottomPanel>
          <CloseWrapper onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </CloseWrapper>
          <StyledDiv>
            <StyledImage src={image} />
            <Wrapper>
              <Title>{title}</Title>
              <Description dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
            </Wrapper>
          </StyledDiv>
          <StyledButton type="primary-blue" onClick={handleOnClick}>
            {ctaLabel}
          </StyledButton>
        </StyledBottomPanel>
      ) : null}
    </>
  );
};

export default BottomPanel;
